.payment__loading-container {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: relative;
}
.payment__loading-container .payment__loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.payment__loading-container .payment__loading-indicator .payment__loading-content {
  margin-top: 1rem;
  display: block;
}
.payment__loading-container .payment__loading-indicator .payment__loading-content .payment__loading-message {
  font-size: 1.3rem;
}
.payment__loading-container .payment__loading-indicator .payment__loading-content .payment__loading-icon {
  font-size: 5rem;
  margin-bottom: 2rem;
  color: #44ca66;
}
